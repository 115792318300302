import { ApiConfigs, useFetch } from '@packages/eh-utils/api';
import { getAtsServiceDirectApiHost } from '@packages/eh-utils/browserEnv';

type TFetchStandardJobCompaniesParams = {
  query: string;
  limit?: number;
};

type TFetchStandardJobCompaniesResponse = {
  data: {
    organisations: Array<{
      name: string;
    }>;
    standard_job_infos: Array<{
      id: string;
      title: string;
    }>;
  };
};

type TUseFetchStandardJobCompaniesProps = Pick<
  ApiConfigs<
    TFetchStandardJobCompaniesResponse,
    TFetchStandardJobCompaniesParams
  >,
  'onCompleted'
>;

const useFetchStandardJobCompanies = ({
  onCompleted,
}: TUseFetchStandardJobCompaniesProps = {}) => {
  const { fetcher, data, loading } = useFetch<
    TFetchStandardJobCompaniesResponse,
    TFetchStandardJobCompaniesParams
  >({
    method: 'GET',
    endpoint: `${getAtsServiceDirectApiHost()}/api/v1/career_page/jobs/autocomplete`,
    onCompleted,
  });

  const getStandardJobCompanies = (query: string, limit?: number) =>
    fetcher({
      query,
      limit,
    });

  return {
    getStandardJobCompanies,
    standardJobCompaniesData: data,
    gettingStandardJobCompanies: loading,
  };
};

export default useFetchStandardJobCompanies;
