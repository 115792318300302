import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
  UseFormSetValue,
} from 'react-hook-form';

import { Select } from '@hero-design/react';

import { ExtraProps, LabelProps } from '@packages/hero-theme/form/types';
import ManuallySelectInput from '@shared/ManuallySelectInput';
import { makeDebounceAction } from '@shared/utils/debounceAction';

import { TSearchFormProps } from 'src/modules/CareersPage/components/CandidateJobSearch/Header';

import useFetchStandardJobCompanies from '../hooks/useFetchStandardJobCompanies';

const JOB_TITLES_NUMBER_LIMIT = 5;
const ORGANISATION_NAME = 'organisationName';
const debounceAction = makeDebounceAction(300);

interface AutoCompleteJobCompanyInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  field: ControllerRenderProps<TFieldValues, TName>;
  fieldState: ControllerFieldState;
  inputProps?: Partial<
    Omit<React.ComponentProps<typeof Select>, 'onChange' | 'value'>
  >;
  labelProps?: LabelProps;
  extraProps?: ExtraProps & {
    queryable?: boolean;
    clearable?: boolean;
    noResultText?: string;
  };
  setValue?: UseFormSetValue<TSearchFormProps>;
}

const AutoCompleteJobCompanyInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  field,
  fieldState,
  inputProps,
  labelProps,
  setValue,
  extraProps,
}: AutoCompleteJobCompanyInputProps<TFieldValues, TName>) => {
  const { onChange, value: fieldValue } = field;
  const [query, setQuery] = useState<string | undefined>();
  const {
    getStandardJobCompanies,
    standardJobCompaniesData,
    gettingStandardJobCompanies,
  } = useFetchStandardJobCompanies();

  const jobTitleOptions = useMemo(() => {
    const responseData = standardJobCompaniesData?.data;

    if (!responseData) {
      return [];
    }

    const organisationNameOptions =
      responseData.organisations?.map(({ name }) => ({
        value: name, // API does not return an ID for organisations, the name is unique
        text: name,
        isCompany: true,
      })) ?? [];

    const standardJobTitleOptions =
      responseData.standard_job_infos?.map(({ id, title }) => ({
        value: id,
        text: title,
        isCompany: false,
      })) ?? [];

    return [
      {
        category: 'Job Titles',
        options: standardJobTitleOptions,
      },
      {
        category: 'Organisations',
        options: organisationNameOptions,
      },
    ];
  }, [standardJobCompaniesData?.data]);

  const onJobTitleChange = useCallback(
    value => {
      const selectedOption = jobTitleOptions
        .flatMap(option => option.options)
        .find(option => option.value === value);

      if (!selectedOption) {
        return;
      }

      if (selectedOption.isCompany === false) {
        onChange(selectedOption.text);
        setValue?.(ORGANISATION_NAME, undefined);
      }

      if (selectedOption.isCompany === true) {
        setQuery(selectedOption.text);
        onChange(undefined);
        setValue?.(ORGANISATION_NAME, selectedOption.text);
      }
    },
    [jobTitleOptions, onChange]
  );

  const onQueryChange = useCallback(
    (newQuery: any) => {
      if (newQuery != null) {
        setValue?.(ORGANISATION_NAME, undefined);

        setQuery(newQuery);
        debounceAction(() => {
          onChange(newQuery);
          if (newQuery?.length >= 3) {
            getStandardJobCompanies(newQuery, JOB_TITLES_NUMBER_LIMIT);
          }
        });
      }
    },
    [getStandardJobCompanies, onChange]
  );

  useEffect(() => {
    if (fieldValue) {
      setQuery(fieldValue);
    }
  }, [fieldValue]);

  return (
    <ManuallySelectInput
      field={{
        ...field,
        onChange: onJobTitleChange,
      }}
      fieldState={fieldState}
      labelProps={labelProps}
      extraProps={{
        ...extraProps,
        query,
        onQueryChange,
      }}
      inputProps={{
        ...inputProps,
        optionPredicate: () => () => true,
        options: jobTitleOptions,
        loading: gettingStandardJobCompanies,
      }}
    />
  );
};

export default AutoCompleteJobCompanyInput;
