import React from 'react';
import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
} from 'react-hook-form';

import { Select } from '@hero-design/react';

import ErrorBoundaryMobile from '@packages/error-boundary/ErrorBoundaryMobile';
import { FieldError } from '@packages/hero-theme/form';
import FieldLabel from '@packages/hero-theme/form/FieldLabel';
import InputContainer from '@packages/hero-theme/form/InputContainer';
import SearchNoResults from '@packages/hero-theme/form/SearchNoResults';
import { ExtraProps, LabelProps } from '@packages/hero-theme/form/types';

interface SelectInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  field: ControllerRenderProps<TFieldValues, TName>;
  fieldState: ControllerFieldState;
  labelProps?: LabelProps;
  inputProps: Omit<React.ComponentProps<typeof Select>, 'onChange' | 'value'>;
  extraProps?: ExtraProps & {
    query?: string;
    queryable?: boolean;
    clearable?: boolean;
    noResultText?: string;
    renderEmptyOption?: () => React.ReactNode;
    onQueryChange?: (query?: string) => void;
  };
}
const ManuallySelectInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  field,
  fieldState,
  inputProps,
  labelProps = {},
  extraProps = {},
}: SelectInputProps<TFieldValues, TName>) => {
  const {
    options,
    loading,
    placeholder,
    optionRenderer,
    optionPredicate,
    selectedOptionRenderer,
  } = inputProps;

  const { text: labelText, required, inline = false } = labelProps;
  const { error } = fieldState;
  const { value, onChange, name } = field;
  const {
    error: extraError,
    queryable = true,
    clearable,
    query,
    onQueryChange,
    noResultText,
    renderEmptyOption,
    'data-test-id': dataTestId,
  } = extraProps;
  const hasError = error != null || extraError != null;
  const queryProps = queryable
    ? {
        query,
        onQueryChange,
        noResults: renderEmptyOption ? (
          renderEmptyOption()
        ) : (
          <SearchNoResults noResultText={noResultText} />
        ),
      }
    : {};

  const id = `job-title-select-input__${name}`;

  return (
    <InputContainer data-test-id={dataTestId} inline={inline}>
      <FieldLabel
        required={required}
        text={labelText}
        hasError={hasError}
        clickable
        htmlFor={id}
        input={
          <ErrorBoundaryMobile>
            <Select
              id={id}
              name={name}
              value={value}
              options={options}
              optionPredicate={optionPredicate}
              optionRenderer={optionRenderer}
              selectedOptionRenderer={selectedOptionRenderer}
              onChange={val => onChange(val ?? null)}
              placeholder={placeholder}
              invalid={hasError}
              loading={loading}
              clearable={clearable}
              {...queryProps}
            />
          </ErrorBoundaryMobile>
        }
      />

      {hasError && (
        <FieldError
          text={(error?.message as string) || (extraError as string)}
        />
      )}
    </InputContainer>
  );
};

export default ManuallySelectInput;
