import { OTHER_OPTION } from '@shared/MultiSelectBaseFilter';

import { DEFAULT_ITEM_PER_PAGE } from 'src/constants';

import { QueryParams } from '../../types';

export const RadiusOptions = [
  { value: 5, text: '5 km' },
  { value: 10, text: '10 km' },
  { value: 25, text: '25 km' },
  { value: 50, text: '50 km' },
  { value: 100, text: '100 km' },
  { value: 200, text: '200 km' },
];

export const LOCALE_TO_COUNTRY: Record<string, string | undefined> = {
  AU: 'AU',
  NZ: 'NZ',
  GB: 'GB',
  MY: 'MY',
  SG: 'SI',
  anywhere: undefined,
};

export const EmploymentTypeOptions = [
  {
    text: 'Full-time',
    value: 'full_time',
  },
  {
    text: 'Part-time',
    value: 'part_time',
  },
  {
    text: 'Contract/Temporary',
    value: 'contract',
  },
  {
    text: 'Casual',
    value: 'casual',
  },
];

export const ExperienceLevelsOptions = [
  {
    text: 'Internship',
    value: 'internship',
  },
  {
    text: 'Entry level',
    value: 'entry_level',
  },
  {
    text: 'Associate',
    value: 'associate',
  },
  {
    text: 'Middle senior',
    value: 'mid_senior',
  },
  {
    text: 'Director',
    value: 'director',
  },
  {
    text: 'Executive',
    value: 'executive',
  },
  OTHER_OPTION,
];

export const DEFAULT_JOB_SEARCH_PARAMS: QueryParams = {
  item_per_page: DEFAULT_ITEM_PER_PAGE,
  page_index: 1,
  country_codes: [],
  job_sector_ids: [],
  employment_types: [],
  experience_levels: [],
  date_posted: '90',
  salary_min: '0',
  sort: 'relevance',
  radius: 25,
};
